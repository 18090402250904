import { cx } from '@emotion/css';
import { useConfig } from '@sortlist-frontend/config';
import { AdvancedMarker, APIProvider, Map, useApiIsLoaded, useMap } from '@vis.gl/react-google-maps';
import { useEffect, useRef, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { GetAgencyOffices, Office } from '_backend/queries/get-agency-offices/get-agency-offices.types';
import { useTracker } from '_core/hooks/use-tracker';
import { findFirstOfficeIndex } from '_features/agency/profile/sections/contact/utils';

import * as S from './contact.styles';

type Props = {
  children?: never;
  agencyName: string;
  agencySlug: string;
  className?: string;
  selectedOffice: Office | null;
  offices: GetAgencyOffices | undefined;
};

const zoomSizes = {
  1: 200,
  2: 90,
  3: 40,
  4: 10,
  5: 8,
  6: 7,
  7: 6,
  8: 5,
  9: 4,
  10: 3,
  11: 2,
  12: 1,
};

const GoogleMap = (props: Props) => {
  const { offices: data, agencyName, agencySlug, selectedOffice, className } = props;
  const { trackUntyped } = useTracker();
  const GoogleMapRef = useRef<HTMLDivElement>(null);
  const isLoadedMaps = useApiIsLoaded();
  const map = useMap();

  const [zoom, setZoom] = useState(11);
  const [center, setCenter] = useState({
    lng: 0,
    lat: 0,
  });

  useEffect(() => {
    if (isLoadedMaps && selectedOffice !== null) {
      const index = findFirstOfficeIndex(data?.offices, selectedOffice);
      if (index !== null) {
        trackUntyped('locationInspected', { agencyName, agencySlug, address: selectedOffice.address, page: 'profile' });
        if (map != null) {
          setCenter({
            lat: selectedOffice.latitude,
            lng: selectedOffice.longitude,
          });
          const bounds = new window.google.maps.LatLngBounds({
            lat: selectedOffice.latitude,
            lng: selectedOffice.longitude,
          });
          map.fitBounds(bounds);
          map.addListener('zoom_changed', () => {
            const currentZoom = map.getZoom();
            if (currentZoom != null && currentZoom > 15) {
              map.setZoom(15);
            }
          });
        }
      }
    }
  }, [selectedOffice]);

  useDeepCompareEffect(() => {
    if (data != null) {
      if (data.offices.length > 0) {
        setCenter({
          lng: data.offices[0].longitude,
          lat: data.offices[0].latitude,
        });
      }
      if (data.offices.length > 1) {
        const longitudes = data.offices.map((office) => office.longitude);
        const latitudes = data.offices.map((office) => office.latitude);
        const latDiff = Math.max(...latitudes) - Math.min(...latitudes);
        const lngDiff = Math.max(...longitudes) - Math.min(...longitudes);

        Object.entries(zoomSizes).some(([key, value]) => {
          if (latDiff > value || lngDiff > value) {
            setZoom(Number(key));
            return true;
          }
          return false;
        });
      }
    }
  }, [data]);

  useEffect(() => {
    const images = GoogleMapRef?.current?.getElementsByTagName('img');
    if (images != null) {
      for (let i = 0; i < images?.length; i++) {
        images[i].setAttribute('alt', `Google map - ${i}`);
      }
    }
  });

  return (
    <S.MapContainer ref={GoogleMapRef} className={cx(className, 'flex-gt-sm bg-secondary-300')}>
      {data?.offices != null && (
        <Map
          defaultCenter={center}
          defaultZoom={zoom}
          style={{ width: '100%', height: '100%' }}
          mapId="contact-google-map">
          {data.offices.map((office) => (
            <AdvancedMarker
              key={office.position}
              position={{ lat: office.latitude, lng: office.longitude }}
              title={office.localityName}
            />
          ))}
        </Map>
      )}
    </S.MapContainer>
  );
};

export const GoogleMapCtn = (props: Props) => {
  const { publicEnv } = useConfig();
  const { googlePlaceApiKey } = publicEnv;

  if (props.offices == null) {
    return null;
  }

  return (
    // When we have the map displayed we should also load the places api in case we want the briefing
    // otherwise loading google maps script 2 times will result in unexpected behaviours
    <APIProvider apiKey={googlePlaceApiKey as string} libraries={['places']}>
      <GoogleMap {...props} />
    </APIProvider>
  );
};
