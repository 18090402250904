import { Fragment } from 'react';

import { GetAgencyProfile } from '_backend/queries/get-agency-profile/get-agency-profile.types';
import { GetAgencyReviews } from '_backend/queries/get-agency-reviews/get-agency-reviews.types';
import { GetAgencySeoDetails } from '_backend/queries/get-agency-seo-details/get-agency-seo-details.types';

import { AgencyStats } from '../../utils/';
import { CollectionPage } from './collectionPage';
import { LocalBusiness } from './localBusiness';
import { ProfilePage } from './profilePage';

type Props = {
  agencyStats: AgencyStats;
  agency: GetAgencyProfile;
  reviews: GetAgencyReviews | null;
  seoDetails: GetAgencySeoDetails | null;
  logo: string | undefined;
  canonical: string;
};

export const JsonLd = (props: Props) => {
  const { agencyStats, agency, reviews, seoDetails, logo, canonical } = props;
  return (
    <Fragment>
      <CollectionPage agencyName={agency.name} seoDetails={seoDetails} />
      <LocalBusiness agencyStats={agencyStats} agency={agency} reviews={reviews} seoDetails={seoDetails} logo={logo} />
      <ProfilePage agencyName={agency.name} agencyUpdatedAt={agency.updated_at} canonical={canonical} />
    </Fragment>
  );
};
