import { cx } from '@emotion/css';
import { InfoOutlined } from '@mui/icons-material';
import { Divider } from '@sortlist-frontend/design-system';
import { Trans } from '@sortlist-frontend/translation/ssr';
import { FC, useState } from 'react';

import { GetAgencyServices } from '_backend/queries/get-agency-services/get-agency-services.types';
import { usePublicAppContext } from '_core/context/public-app-context';
import { useTracker } from '_core/hooks/use-tracker';

import { maxNumberOfServicesDisplayed } from '../services-panel';
import { ServiceAbout, ServiceClients, ServicePreview, ServiceReviews, ServiceWorks } from './index';

type Props = {
  agencyLogoUrl: string;
  agencyName: string;
  agencySlug: string;
  className?: string;
  handleActivePanel: () => void;
  index: number;
  service: GetAgencyServices['services'][0];
  servicesCount: number;
  totalWorksCount: number;
  visibleServicesCount: number;
};

export const ServiceCard: FC<Props> = (props) => {
  const {
    agencyLogoUrl,
    agencyName,
    agencySlug,
    className,
    handleActivePanel,
    index,
    service,
    servicesCount,
    totalWorksCount,
    visibleServicesCount,
  } = {
    ...props,
  };
  const { trackUntyped } = useTracker();
  const { getUrl, locale, domainInfo } = usePublicAppContext();
  const countrySlug = domainInfo?.getCountrySlug(locale);
  const [toggled, setToggled] = useState(false);
  const hasInfo =
    service.works.count +
      service.description.length +
      service.skills.count +
      service.clients.count +
      service.reviews.count +
      service.works.count >
    0;

  const lastItem: boolean = visibleServicesCount === index + 1 || servicesCount === index + 1;
  const isMoreToSee: boolean = servicesCount > maxNumberOfServicesDisplayed;

  const toggleService = (service: GetAgencyServices['services'][0], experienceLevel: number) => {
    if (!toggled) {
      trackUntyped('serviceInspected', {
        agencyName,
        agencySlug,
        expertise_id: service.expertise_id,
        experience_level: experienceLevel,
      });
    }
    setToggled(!toggled);
  };

  return (
    <li className={cx(className, 'layout-column layout-align-start-stretch')}>
      <ServicePreview
        service={service}
        toggled={toggled}
        hasInfo={hasInfo}
        servicesCount={servicesCount}
        totalWorksCount={totalWorksCount}
        handlePanelToggle={(service, experienceLevel) => hasInfo && toggleService(service, experienceLevel)}
      />
      <Divider className={cx(lastItem && !isMoreToSee && 'hide')} />
      <div
        className={cx(
          toggled ? 'show' : 'hide',
          lastItem && !isMoreToSee && 'border-top border-secondary-300',
          'p-gt-xs-32 p-8 bg-secondary-100',
        )}>
        <ServiceAbout
          service={service}
          agencySlug={agencySlug}
          description={service.description}
          skills={service.skills.data}
        />
        {service.works.count > 0 && (
          <ServiceWorks
            agencyName={agencyName}
            agencySlug={agencySlug}
            agencyLogoUrl={agencyLogoUrl}
            works={service.works.data}
            worksCount={service.works.count}
            serviceName={service.name}
            handleActivePanel={handleActivePanel}
          />
        )}
        {service.reviews.count > 0 && (
          <ServiceReviews
            handleActivePanel={handleActivePanel}
            reviews={service.reviews.data}
            serviceName={service.name}
            agencySlug={agencySlug}
          />
        )}
        {service.clients.count > 0 && (
          <ServiceClients
            handleActivePanel={handleActivePanel}
            clients={service.clients.data}
            serviceName={service.name}
            agencySlug={agencySlug}
          />
        )}
        <div className="pt-8 px-24 mt-16 layout-column">
          <Divider />
          <span className="small pt-32 pb-xs-24 flex-nogrow layout-row layout-align-start-center">
            <InfoOutlined style={{ fontSize: 14 }} className="mr-8 text-secondary-900" />
            <span className="text-secondary-900">
              <Trans i18nKey={'agency:profile.services.learnMore'}>
                <span>Learn more about </span>
                <a
                  href={getUrl('longtails.hubPage', {
                    expertiseSlug: service.expertise_slug,
                    countrySlug: countrySlug ?? undefined,
                  })}
                  className="underline cursor-pointer"
                  target="_blank">
                  {{ serviceName: service.name }}
                </a>
              </Trans>
            </span>
          </span>
        </div>
      </div>
      <Divider className={cx(toggled ? 'show' : 'hide')} />
    </li>
  );
};
