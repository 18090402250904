import { getJsonSafeString } from '@sortlist-frontend/utils';
import { ProfilePageJsonLd } from 'next-seo';

type Props = {
  agencyName: string;
  agencyUpdatedAt: string;
  canonical: string;
};

export const ProfilePage = (props: Props) => {
  const { agencyName, agencyUpdatedAt, canonical } = props;
  const mainEntity = {
    '@type': 'Organization',
    name: agencyName,
    url: canonical,
  };

  return (
    <ProfilePageJsonLd
      mainEntity={mainEntity}
      breadcrumb={`Sortlist > ${getJsonSafeString(agencyName)}`}
      lastReviewed={agencyUpdatedAt}
    />
  );
};
